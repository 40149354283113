<template>
  <div class="sp_sdffd">
    <div class="search-s">
      <div style="float:left;margin-left:11px">
        <span style="margin-right:10px">艺术类别</span>
        <el-radio-group v-model="tip" @change="getPlanNum">
          <el-radio v-if="typeList.indexOf('美术') != -1" label="美术">美术</el-radio>
          <el-radio v-else disabled label="美术">美术</el-radio>
          <el-radio v-if="typeList.indexOf('音乐') != -1" label="音乐">音乐</el-radio>
          <el-radio v-else disabled label="音乐">音乐</el-radio>
          <el-radio v-if="typeList.indexOf('播音主持') != -1" label="播音主持">播音主持</el-radio>
          <el-radio v-else disabled label="播音主持">播音主持</el-radio>
          <el-radio v-if="typeList.indexOf('编导制作') != -1" label="编导制作">编导制作</el-radio>
          <el-radio v-else disabled label="编导制作">编导制作</el-radio>
          <el-radio v-if="typeList.indexOf('表演') != -1" label="表演">表演</el-radio>
          <el-radio v-else disabled label="表演">表演</el-radio>
          <el-radio v-if="typeList.indexOf('书法') != -1" label="书法">书法</el-radio>
          <el-radio v-else disabled label="书法">书法</el-radio>
          <el-radio v-if="typeList.indexOf('体育舞蹈') != -1" label="体育舞蹈">体育舞蹈</el-radio>
          <el-radio v-else disabled label="体育舞蹈">体育舞蹈</el-radio>
        </el-radio-group>
      </div>

      <span class="txt">年份</span>
      <el-select v-model="year" style="width:83px;" placeholder="请选择" @change="getPlanNum">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <span class="txt">选择科类</span>
      <el-radio-group v-model="wenli" @change="getPlanNum">
        <el-radio label="文科">文科</el-radio>
        <el-radio label="理科">理科</el-radio>
      </el-radio-group>
    </div>
    <el-table :data="tableData" :header-cell-style="rowClass" stripe style="width: 100%">
      <el-table-column prop="year" label="年份" width="55" />
      <el-table-column prop="wenli" label="科类" width="55" />
      <el-table-column prop="specialty_name" label="专业名称" />
      <el-table-column prop="plan_num" label="计划数" width="110" />
      <el-table-column prop="recruitment_num" label="录取数" width="110" />
      <el-table-column prop="wen_scale" label="文化成绩比例" width="110" />
      <el-table-column prop="zhuanye_scale" label="专业成绩比例" width="110" />
      <el-table-column prop="min_score" label="投档最低分" />
      <el-table-column prop="order_rule" label="排序方法" width="240" />
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'SpScore',
  props: ['school_id'],
  data() {
    return {
      year: 2022,
      wenli: '文科',
      tip: '美术',
      typeList: [],
      tableData: [],
      options: [
        {
          value: '2022'
        },
        {
          value: '2021'
        },
        {
          value: '2020'
        },
        {
          value: '2019'
        },
        {
          value: '2018'
        },
        {
          value: '2017'
        },
        {
          value: '2016'
        },
        {
          value: '2015'
        },
        {
          value: '2014'
        },
        {
          value: '2013'
        }
      ]
    }
  },
  mounted() {
    this.getPlanNum()
  },
  methods: {
    // 表格样式设置
    rowClass() {
      return 'background:#8aa0f1; color:white'
    },
    getPlanNum() {
      this.$fecth
        .post('art_school_score/getData', {
          school_id: this.school_id,
          art_type: this.tip,
          wenli: this.wenli,
          year: this.year
        })
        .then(res => {
          this.tableData = res.lists
          this.typeList = res.typeList
        })
    }
  }
}
</script>

<style lang="less" >
.sp_sdffd {

  .el-input--suffix .el-input__inner {
    background: none;
    margin: 10px 0;
    height: 26px;
  }

  .el-radio__input.is-checked+.el-radio__label {
    color: white;
  }

  .el-select .el-input.is-focus .el-input__inner {
    border-color: white;
  }

  .el-select .el-input__inner:focus {
    border-color: white;
  }

  .el-input__inner {
    color: white;
  }

  .el-radio {
    color: white;
  }

  .search-s {
    background: #717dec;
    text-align: right;
    height: 48px;
    line-height: 48px;
    color: white;
    padding-right: 10px;

    .txt {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
</style>
