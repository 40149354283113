<template>
  <div>
    <div class="title">
      <div></div>
    </div>
    <sp-score :school_id="this.$route.query.cid" />
  </div>
</template>

<script>
import spScore from "./components/spScore";
export default {
  name: '',
  data() {
    return {
    };
  },
  components: {
    spScore,
  },

};
</script>

<style scoped lang='less'>
.title {
  margin: 40px 0px;
}
</style>
